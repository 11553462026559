import { useI18n } from "vue-i18n";

export const useLocalStorage = () => {
  const getLocalAccessToken = () => {
    let token = localStorage.getItem("gs-tkn")
      ? localStorage.getItem("gs-tkn")
      : null;
    return token;
  };

  const updateLocalAccessToken = (token) => {
    localStorage.setItem("gs-tkn", token);
  };

  const removeToken = () => {
    localStorage.removeItem("gs-tkn");
  };

  const getUserLang = () => {
    const { locale } = useI18n({ useScope: "global" });
    const lang = JSON.parse(localStorage.getItem("gs_lang")) || "en";
    locale.value = lang;
    return lang;
  };

  const updateUserLang = (lang) => {
    localStorage.setItem("gs_lang", JSON.stringify(lang));
  };

  const removeLang = () => {
    localStorage.removeItem("gs_lang");
  };

  const getUserInfo = () => {
    let user = localStorage.getItem("gs-user")
      ? JSON.parse(localStorage.getItem("gs-user"))
      : null;
    return user;
  };

  const getUseTreament = () => {
    const treament = localStorage.getItem("gs-treament")
      ? localStorage.getItem("gs-treament")
      : null;
    return treament;
  };

  const getUserCompanyInfo = () => {
    let company = localStorage.getItem("gs-company")
      ? JSON.parse(localStorage.getItem("gs-company"))
      : null;
    return company;
  };

  return {
    removeLang,
    removeToken,
    getUserLang,
    getUserInfo,
    getUseTreament,
    updateUserLang,
    getUserCompanyInfo,
    getLocalAccessToken,
    updateLocalAccessToken,
  };
};
