import { useLocalStorage } from '@/composables/useLocalStorage';
import { Role, hasRole } from '@/composables/useRoles';
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/carbon',
    component: () => import('../views/layouts/CarbonFootPrint.vue'),
    children: [
      {
        path: '/carbon',
        name: 'CalculatorRoute',
        component: () => import('../views/inventories/Index.vue'),
        meta: {
          authorize: []
        }
      },
      {
        path: '/carbon/setting/:id',
        name: 'CalculatorSettingRoute',
        component: () => import('../views/inventories/Settings.vue'),
        meta: {
          authorize: []
        }
      },
      {
        path: '/power/:id',
        name: 'PowerRoute',
        component: () => import('../views/calculator/Electricity.vue'),
        meta: {
          authorize: []
        }
      },
      {
        path: '/transport/:id',
        name: 'TransportRoute',
        component: () => import('../views/calculator/Transport.vue'),
        meta: {
          authorize: []
        }
      },
      {
        path: '/waste/:id',
        name: 'WasteRoute',
        component: () => import('../views/calculator/Waste.vue'),
        meta: {
          authorize: []
        }
      },
      {
        path: '/sewage-water/:id',
        name: 'SewageRoute',
        component: () => import('../views/calculator/Sewage.vue'),
        meta: {
          authorize: []
        }
      },
      {
        path: '/carbon/report',
        name: 'ReportRoute',
        component: () => import('../views/calculator/Report.vue'),
        meta: {
          authorize: []
        }
      },
      {
        path: '/leaks/:id',
        name: 'LeaksRoute',
        component: () => import('../views/calculator/Leaks.vue'),
        meta: {
          authorize: []
        }
      },
      {
        path: '/reductions/:id',
        name: 'ReductionsRoute',
        component: () => import('../views/calculator/Reductions.vue'),
        meta: {
          authorize: []
        }
      },
      {
        path: '/other-emissions/:id',
        name: 'OtherEmissionsRoute',
        component: () => import('../views/calculator/OtherEmissions.vue'),
        meta: {
          authorize: []
        }
      },
      {
        path: '/carbon/setting/:id',
        name: 'SettingRoute',
        component: () => import('../views/profile/Setting.vue'),
        meta: {
          authorize: []
        }
      },
    ]
  },
  {
    path: "/gri",
    component: () => import('../views/layouts/GriMainContent'),
    children: [
      {
        path: '/gri',
        name: 'GridL1Route',
        component: () => import('../views/gri/Questionnaire.vue'),
        meta: {
          authorize: []
        }
      },
    ]
  },
  {
    path: '/',
    component: () => import('../views/layouts/Main.vue'),
    children: [
      {
        path: '/',
        name: 'HomeRoute',
        component: () => import('../views/home/Dashboard.vue'),
        meta: {
          authorize: []
        }
      },
      {
        path: '/onboarding',
        name: 'OnbordingRoute',
        component: () => import('../views/calculator/Onboarding.vue'),
        meta: {
          authorize: []
        }
      },
      {
        path: '/account',
        name: 'AccountRoute',
        component: () => import('../views/profile/Account.vue'),
        meta: {
          authorize: []
        }
      },
      {
        path: '/manage-team',
        name: 'ManageTeamRoute',
        component: () => import('../views/profile/ManageTeam.vue'),
        meta: {
          authorize: [Role.Owner, Role.Admin]
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'LoginRoute',
    meta: {},
    component: () => import('../views/auth/Login.vue'),

  },
  {
    path: '/create-account',
    name: 'CreateAccountRoute',
    meta: {},
    component: () => import('../views/Account/CreateAccount.vue'),

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: 'smooth', }
  },
})

router.beforeEach((to, from, next) => {
  const { getLocalAccessToken, getUserInfo } = useLocalStorage();
  const isAuthenticated = getLocalAccessToken();
  // Si el usuario intenta ir a una ruta protegida sin un token, redirigir al login

  const { authorize } = to.meta;
  const currentUser = getUserInfo();

  if (authorize) {
    if ((to.name !== 'LoginRoute' && to.name !== 'CreateAccountRoute') && !isAuthenticated) {
      next({ name: 'LoginRoute' });
    } 
    if (authorize.length && !hasRole(authorize, currentUser.rl)) {
        return next({ path: '/' });
    }
  }
  next();
});

export default router
