import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router'
import vSelect from "vue-select";
import VueFeather from 'vue-feather';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import './assets/scss/main.scss'
import i18n from './i18n'

const app = createApp(App);
app.use(i18n);
app.use(createPinia());
app.component("v-select", vSelect);
app.component(VueFeather.name, VueFeather);
app.use(router);
app.use(Toast);
app.component('VueDatePicker', VueDatePicker);
app.mount('#app');