<template>
  <router-view />
</template>
<script>
// import { onMounted } from 'vue';
// import { useLocalStorage } from './composables/useLocalStorage';

export default {
  name: 'App',
  setup: () => {
    // const { getLocalAccessToken } = useLocalStorage();
    // const isAuthenticated = getLocalAccessToken();

    // const loadScript = () => {
    //   const script = document.createElement('script');
    //   script.id = 'writwiz-connector';
    //   script.src = 'https://api.chronox.ai/chatbot.js?integration_token=hXBoxqYe1729103859715';
    //   document.body.appendChild(script);
    // };

    // onMounted(() => {
    //   if (isAuthenticated) { // Verifica si el usuario está logueado
    //     loadScript();
    //   }
    // });
  }
}
</script>